import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'
import { ExportForm } from '@/classes/ExportForm'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import BrowserCache from '@/services/browser-cache.js'
import GeneralStatsService from '@/services/general-stats.service'
import StatsService from '@/services/stats.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DateRange from '@/components/DateRange/DateRange.vue'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import UserInteractionWhatsapp from '@/components/Stats/Whatsapp/UserInteractionWhatsapp.vue'
import EventBus from '@/util/EventBus'
import StatsMixin from '../../Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import { WhatsappGraphicData } from '@/classes/stats/WhatsappGraphicData'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import RoutesService from '@/services/whatsapp/routes.service'

export default {
  name: 'ChannelWhatsappStats',
  components: {
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    HeaderTopDashboard,
    ImpactCostGeneral,
    UserInteractionWhatsapp,
    StatCard,
    RadialBarWithLegend,
    RadialBar,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      debouncedInput: '',
      timeout: null,
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalCampaigns: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      totals: {},
      totalCost: 0,
      impactCost: new ImpactCost(),
      exportForm: new ExportForm(),
      ready: true,
      userInteractionWhatsapp: [],
      deliveredRatioGraphic: null,
      clicksRatioGraphicData: null,
      readyStats: false,
      cardWhatsappData: {
        show: false,
        cards: [],
      },
    }
  },
  methods: {
    sumItem (items, key) {
      let total = 0
      items.map((item) => {
        total = parseInt(total) + parseInt(item[key])
      })
      return total
    },

    sumItemCost (items, key) {
      let total = 0
      items.map((item) => {
        total = parseFloat(total) + parseFloat(item[key])
      })
      return total.toFixed(3)
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getWhatsappChannelDatatable(params)
        .then(
          (response) => {
            // console.log(response)
            this.readyStats = false
            this.totals = response.totals
            this.content = response.dataTable.data
            this.itemsPerPage = parseInt(response.dataTable.per_page)
            this.page = response.dataTable.current_page
            this.totalCampaigns = response.dataTable.total
            this.setUserInteractionWhatsapp(response.dataTable)
            this.setGraphicsData(response)
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
          this.readyStats = true
        })
      this.getimpactCostStats()
    },

    setUserInteractionWhatsapp (campaigns) {
      this.userInteractionWhatsapp = campaigns
    },

    setGraphicsData (response) {
      const whatsappGraphicData = new WhatsappGraphicData(response, this)
      this.cardWhatsappData.cards = whatsappGraphicData.getCardWhatsappGraphicData()
      this.cardWhatsappData.show = true
      this.deliveredRatioGraphic = whatsappGraphicData.getDeliveredRatioGraphicGraphicData()
      this.clicksRatioGraphicData = whatsappGraphicData.getClicksRatioGraphicData()
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },

    setPieChart (totals) {
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.pending = this.calculatePending(totals)
      this.resetReady()
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      this.setExportData()
      const url = getEnv('backend_endpoint') + 'api/stats/whatsapp/channel-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
      window.location.href = url
    },

    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    updateImpactCostChartData (data) {
      this.impactCost.series = [
        {
          name: 'whatsapp',
          color: '#5BCE09',
          data: data.whatsapp,
        },
      ]
    },

    getimpactCostStats () {
      if (!this.globalControl.initDate || !this.globalControl.endDate) {
        return
      }

      GeneralStatsService.getImpactCostStatsWhatsApp({
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        globals: this.globalControl.globals,
        daysOrHour: this.impactCost.daysOrHour,
      }).then((data) => {
        // console.log('DATA ', data)
        this.updateImpactCostChartData(data)
      })
    },

    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.getDataFromApi()
    },
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Campaña'),
          value: 'campaign_name',
        },
        {
          text: this.$t('Categoría'),
          value: 'template_whatsapp_categories_name',
        },
        {
          text: this.$t('Fecha'),
          value: 'campaign_creation_date',
        },
        {
          text: this.$t('Actualización'),
          value: 'campaign_sending_updation_date',
        },
        {
          text: this.$t('Contactos'),
          value: 'whatsapp_sendings_tracking_total',
        },
        {
          text: this.$t('Enviados'),
          value: 'whatsapp_sendings_tracking_sent',
        },
        {
          text: this.$t('Entregados'),
          value: 'whatsapp_sendings_tracking_delivered',
        },
        {
          text: this.$t('Leídos'),
          value: 'whatsapp_sendings_tracking_read',
        },
        {
          text: this.$t('Respondidos'),
          value: 'whatsapp_sendings_tracking_responded',
        },
        {
          text: this.$t('No entregados'),
          value: 'whatsapp_sendings_tracking_undelivered',
        },
        {
          text: this.$t('Fallidos'),
          value: 'whatsapp_sendings_tracking_failed',
        },
        {
          text: this.$t('Clics'),
          value: 'whatsapp_sendings_click_tracking_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'whatsapp_sendings_tracking_unsubscribed',
        },
        {
          text: this.$t('Coste'),
          value: 'cost',
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
  },
  watch: {
    'impactCost.daysOrHour': function (newVal, oldVal) {
      this.getimpactCostStats()
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  async created () {
    RoutesService.getCurrentUserAllRoutes()
    .then((routes) => {
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          routes.length
            ? this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
            : this.$router.push({ name: 'AssistantPage' }).catch(() => { this.$forceUpdate() })
        },
        () => this.$router.push({ name: 'WhatsappSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
      )
    })
    this.globalControl.globals = false
  },
}
